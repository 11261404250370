<template>
    <div>
        <a-modal :maskClosable="false" :width="width" @cancel=" cancel " class="at-modal" v-model="active"
                 :footer="null">
            <div class="modal-close-btn" slot="closeIcon">

            </div>
            <div slot="title" class="at-modal-title">
                <slot name="title">{{ title }}</slot>
            </div>
            <div class="at-modal-content">
                <slot/>
            </div>
            <div class="at-modal-footer" v-if="footer !== null ">
                <button @click="cancel" class="btn btn-white">{{ cancelButtonText }}</button>
                <button @click="ok" class="btn">{{ confirmButtonText }}</button>
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    name: "AtModal",
    model: {
        prop: 'show',
        event: 'change'
    },
    props: {
        show: Boolean,
        title: String,
        width: String,
        footer: {
            type:String,
            default(){
                return "0"
            }
        },
        cancelButtonText: {
            type: String,
            default() {
                return '取消'
            }
        },
        confirmButtonText: {
            type: String,
            default() {
                return '确定'
            }
        }
    },
    data() {
        return {
            active: false
        }
    },
    watch: {
        show() {
            this.active = this.show
        }
    },
    mounted() {
        this.active = this.show
    },
    methods: {
        cancel() {
            this.$emit('change', false)
        },
        ok() {
            this.$emit('ok')
        }
    }
}
</script>

<style lang="less" scoped>
.modal-close-btn {
    width: 16px;
    height: 16px;
    background-image: url("/assets/icons/modal-close.png");
    background-size: contain;
    display: inline-block;
}

.at-modal {
    /deep/ .ant-modal-content {
        padding: 24px;
    }

    /deep/ .ant-modal-header {
        padding-top: 0;
        padding-left: 0;
        border-bottom: 1px solid #EEEEEE;
    }

    /deep/ .ant-modal-body {
        padding: 24px 0 8px 0;

    }
}

.at-modal-title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
}

.at-modal-footer {
    margin-top: 65px;
    display: flex;
    justify-content: center;
}

.btn-white {
    margin-right: 16px;
}

</style>